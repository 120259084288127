@import "../variables.module";

.value-display {
  display: inline-flex;
  align-items: center;
  gap: 4px;

  .value-number-display {
    color: rgba(232, 229, 255, 0.85);
  }
}

.custom-value-display {
  display: inline-flex;
  align-items: center;
  gap: 4px;

  span {
    color: rgba(232, 229, 255, 0.75);
    font-size: 13px;
  }
}

.currency-icon {
  width: 22px;
  display: inline-flex;
  align-items: center;

  &.xlarge {
    width: 25px;
  }

  &.large {
    width: 20px;
  }

  &.mid {
    width: 17px;
  }

  &.small {
    width: 14px;
    height: 14px;
  }

  &.balance {
    width: 15px;
  }

  &.inline-popup-icon {
    width: 13px;
    display: block;
  }

  svg {
    width: 100%;
  }

  .text-icon {
    font-size: inherit;
    padding: 0;
    color: #daa030;
  }
}

.span-class {
  color: rgba(232, 229, 255, 0.75);
  font-size: 12px;
  display: inline-block;
}

.text-currency {
  gap: 2px;
}

.text-display {
  display: inline-flex;
  align-items: center;
  font-weight: 500;

  .value-number-display {
  }
}

.value {
  &.value-number-display {
    color: rgba(232, 229, 255, 0.85);

    // Add support for custom symbol color
    &[style*="--symbol-color"] {
      span[data-currency-symbol="true"] {
        color: var(--symbol-color);
      }
    }
  }
}
