@import "../variables.module";

.horizontal-line {
  background-color: rgba(255, 255, 255, 0.2);
  margin: $spacingSM 0;
  height: 1px;
  width: 100%;
}

.popup-container {
  height: 1000px;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: $padding * 4;
  width: 200px;
  z-index: 10010;
}

.popup-content {
  align-items: center;
  border-radius: 5px;
  display: flex;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15));
  opacity: 0;
  padding: $spacingSM $spacingMD;
  /* Enable pointer events for the container itself */
  pointer-events: auto;
  position: fixed;
  right: $Dmw;
  transform: translateX(100%);
  width: 300px;

  /* Disable pointer events for all child elements */
  * {
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
  }

  .close-icon {
    display: flex;
    justify-content: flex-end;

    svg {
      width: 10px;
    }
  }

  .flex-container {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
  }

  .icon-container {
    align-items: baseline;
    display: flex;

    svg {
      width: 20px;
    }

    &.icon-rotation {
      transform: rotate(180deg);
    }
  }

  .icon-text-content {
    align-items: center;
    display: flex;
    width: 80%;

    span {
      color: $alertFontColor;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-break: auto;
      line-height: 1.3em;
    }

    .value-display {
      display: inline-flex;
      font-weight: 700;
      margin-right: 0px;
      transform: translateY(1px);

      .token-content {
        margin-right: 1px;
        transform: translateY(1px);

        svg {
          width: 12px;
        }
      }
    }
  }

  .loading-bar {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: $spacingMD;
    bottom: $spacingSM;
    height: 3px;
    left: $spacingMD;
    overflow: hidden;
    position: absolute;
    width: 87%;

    .bar {
      border-radius: 20px;
      bottom: 0;
      height: 100%;
      width: 100%;
    }
  }

  &.popup-type-0 {
    background:
      radial-gradient(
        ellipse at top,
        rgba(209, 64, 63, 0),
        rgba(209, 64, 63, 0.2)
      ),
      radial-gradient(
        ellipse at bottom,
        rgba(209, 64, 63, 0.2),
        rgba(209, 64, 63, 0)
      ),
      $secondaryBackgroundColor;
    border: 1px solid $alertFailedTransparent;

    .loading-bar {
      .bar {
        background-color: $alertFailed;
      }
    }

    .icon-container {
      svg {
        width: 18px;
      }
    }
  }

  &.popup-type-1 {
    background:
      linear-gradient(
        0deg,
        rgba(75, 174, 79, 20%) 0%,
        rgba(75, 174, 79, 10%) 100%
      ),
      linear-gradient(#191f3b, #191f3b);
    border: 1px solid $alertSuccessTransparent;

    .loading-bar .bar {
      background-color: $successGreen;
    }

    .icon-container {
      svg path {
        fill: $successGreen;
        stroke: $successGreen;
      }
    }
  }

  &.popup-type-2 {
    background:
      radial-gradient(
        ellipse at top,
        rgba(109, 114, 136, 0),
        rgba(109, 114, 136, 0.2)
      ),
      radial-gradient(
        ellipse at bottom,
        rgba(109, 114, 136, 0.2),
        rgba(109, 114, 136, 0.1)
      ),
      $secondaryBackgroundColor;
    border: 1px solid $alertGenericTransparent;

    .loading-bar .bar {
      background-color: #6d7288;
    }

    .icon-container {
      display: none;
    }
  }
}

.value-display-container {
  display: inline-flex;
  gap: 2px;
  margin-left: 1px;
  //Need this work outside the tiop of
  transform: translateY(1px);

  svg {
    transform: translateY(0px);
  }

  .token-content {
    transform: translateY(1px);
  }

  .value-icon {
    transform: translateY(0px);
  }

  .rain-icon {
    display: inline-block;
    margin: 0px 3px 0px 1px;
  }

  :nth-child(1) {
    align-items: flex-start;
  }
}
