@import "../variables.module";

.value-icon {
  //    width: 100%;
  //    height: 20px;
  //    display: block;
  // position:
  // flex-grow: 1;
  // flex-basis: 25px;
  width: 20px;
  display: inline-flex;
  &.large {
    width: 18px;
  }
  &.mid {
    width: 15px;
  }
  &.small {
    width: 13px;
  }
  &.inline-popup-icon {
    // vertical-align: text-top;
    width: 13px;
    display: block;
  }
  svg {
    width: 100%;
  }

  .text-icon {
    font-size: 14px;
    padding: 0;
    color: #daa030;
  }
}

@media (max-width: $breakpoint-one) {
}

@media (prefers-color-scheme: dark) {
}
