.raffle-side-bar-container {
  //   border: 1px solid red;
  //   position: absolute;
  top: 0;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;

  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  align-items: center;

  border-bottom: 1px solid #ffffff2c;

  .left-side {
    display: flex;
    gap: 10px;

    .icon-container {
    }
    .heading-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .title {
        /* $100,000 */

        font-style: italic;
        font-weight: 700;
        font-size: 15px;

        /* identical to box height, or 15px */
        text-transform: uppercase;

        background: linear-gradient(181.17deg, #ffffff 39.55%, #e7e6de 99%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-wrap: nowrap;
        text-shadow: 0px 0px 18.0339px rgba(255, 255, 255, 0.5);
      }
      .sub-title {
        font-style: italic;
        font-weight: 700;
        font-size: 11px;
        line-height: 100%;
        padding: 2px 0;
        padding-right: 2px;
        text-wrap: nowrap;
        /* identical to box height, or 11px */
        text-transform: uppercase;

        background: linear-gradient(189.63deg, #ffffff 11.2%, #ffd706 97.93%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        text-shadow: 0px 0px 18.0339px rgba(222, 181, 52, 0.5);
      }
    }
  }

  .right-side {
    .days-left-container {
      background: linear-gradient(0deg, #ffe356 20%, #ffe35601 100%);
      border-radius: 4px;
      padding: 1px;
      .days-left-container-inner {
        border: 1px solid rgba(255, 227, 86, 0);
        background: #2e2f32;
        border-radius: inherit;
        padding: 3px;
      }
      span {
        // -webkit-text-stroke-width: 0.4687820076942444;
        // -webkit-text-stroke-color: #000;

        font-size: 11px;
        font-style: italic;
        font-weight: 700;
        line-height: 100%; /* 11px */
        letter-spacing: 0.165px;
        text-transform: uppercase;
        text-shadow:
          -1px -1px 0 #000,
          1px -1px 0 #000,
          -1px 1px 0 #000,
          1px 1px 0 #000,
          0.593px 0.593px 0 #ffe356; //

        &:first-child {
          padding-right: 2px;
        }
      }
    }
  }
}

.raffle-mini-side-bar-container {
  border-bottom: 1px solid #ffffff2c;
  //   padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon-container {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    position: relative;
    transition: all 0.3s ease-in-out;

    .background-glow {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: radial-gradient(
        circle at center bottom,
        #ffd788 0%,
        rgba(0, 0, 255, 0) 100%
      );
      transition: opacity 0.3s ease-in-out;
      border-radius: inherit;
    }
    img {
      transition: all 0.3s ease-in-out;
      z-index: 1;
    }

    &:hover {
      border: 1px solid #ffd788;
      .background-glow {
        opacity: 1;
      }
      img {
        transform: scale(1.05);
        translate: 0 -2px;
      }
    }
  }
}
